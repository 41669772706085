<template>
  <multiselect
      class="health-plan-combo form-control"
      ref="health_plan_id"
      v-model="value.health_plan_id"
      :options="patientHealthPlans"
      track-by="id"
      label="fantasy_name"
      :showLabels="false"
      :allowEmpty="false"
      placeholder="Selecionar Convênio..."
      :disabled="disabled"
  >
    <template #caret>
      <div class="chevron">
        <ChevronDown />
      </div>
    </template>
    <template #singleLabel="props">
      <span class="option__title">{{ props.option.fantasy_name }}</span>
    </template>
  </multiselect>
</template>
<script>

export default {
  name: "SimpleHealthPlanSelect",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
  },
  props: {
    value: {
      type: Object,
      default: () => { return { health_plan_id: null } }
    },
    clinic_id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      patientHealthPlans: []
    }
  },
  mounted() {
    this.getPatientHealthPlans()
  },
  methods: {
    getPatientHealthPlans() {
      if(this.value.patient_id) {
        this.api.getPatientHealthPlans(this.clinic_id, this.value.patient_id.id)
        .then(res => {
          this.patientHealthPlans = res.data.map(plan => plan.health_plan)
        })
        .catch(err => {
          this.$toast.error('Erro ao carregar lista de Pacientes. Por favor, atualize o sistema. Se o problema persistir, contate o suporte.')
          console.error(err)
        })
      }
    }
  },
  watch: {
    'value.patient_id'() {
      this.value.health_plan_id = null
      this.getPatientHealthPlans()
    }
  }
}
</script>

<style lang="scss">
 .health-plan-combo.form-control {
   padding: 0;
 }
</style>
